.tbl-container.post-item-small {
  gap: 1rem;
  grid-template-columns: 1fr 2fr;
  display: grid;
  margin-bottom: 1rem;
}
.post-item-small .title {
  margin-top: 0;
  margin-bottom: 4px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}
.small-post-meta {
  display: flex;
  align-items: center;
  margin: 0;
}
.small-post-meta span {
  list-style: none;
  margin-right: 8px;
  color: #7a7a7a;
  font-size: 12px;
  white-space: nowrap;
}

.blog_banner {
  background-image: url(../../images//banner2.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.intrest_button {
  border-radius: 4px;
  border: 1px solid var(--clr-primary);
  background-color: var(--clr-primary);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  width: 20%;
  margin: 37px 10px;
  text-align: center;
}
.related_blog {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.latest_featurs {
  grid-template-columns: repeat(4, auto);
  display: grid;
}

.MuiBox-root-7 {
  padding: 24px 0px !important;
}
.data_blog {
  display: block;
  width: 100%;
  max-width: 100%;
  float: left;
  position: relative;
}

.blog-section-related {
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: left;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}
.blog-section-related::after {
  background: #222;
  bottom: 0;
  content: "";
  height: 0.1rem;
  left: 0;
  position: absolute;
  width: 80px;
}
.blog_title_main {
  color: var(--clr-primary);
}
/* .PrivateTabIndicator-colorSecondary-16 {
    background-color: #1e42e1;
  } */
.blog-title {
  display: block;
  width: 100%;
  float: left;
  position: relative;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
}
.blog-description {
  display: block;
  width: 100%;
  float: left;
  position: relative;
  line-height: 24px;
  color: #888;
}

.interest_image {
  text-align: center;
  /* width: 50px; */
  display: flex;
  justify-content: center;
}
.interest_image img {
  border-radius: 50%;
  height: 100px;
  text-align: center;
  /* width: 50px; */
}
.intrest_heading {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
}
.intrest_data {
  margin: 2px;
}

.Card_section_asy {
  padding-left: 10%;
  padding-right: 10%;
}

/* 
    ///seprate pages  */

.container_Pages_sugg {
  justify-content: space-evenly;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto auto auto;
}
.card_suggest_page {
  margin: 5px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: #fff;
  overflow: hidden;
  width: 345px;
  border: 1px solid #e6e6e6;
}
.card .card-header {
  margin-bottom: 0;
  align-items: center !important;
  border: 0;
  border-bottom: 1px solid #f1f1f1;
  padding: 0px;
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1pxsolidrgba (0, 0, 0, 0.125);
  padding: 0px;
}
.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag-teal {
  background-color: #47bcd4;
}
.tag-purple {
  background-color: #5e76bf;
}
.tag-pink {
  background-color: #cd5b9f;
}

.card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}
.event_pages_heading {
  font-size: 13px;
  margin: 0 0 40px;
}

.like_btn_sugg {
  background: #3661bb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  color: #fff;
  font-family: Poppins;
  padding: 8px;
  border: none;
}
.Card_section.mt-3 {
  margin-bottom: 5%;
}
.span.auther_name {
  font-size: 13px;
  font-weight: 600;
}
.MuiBox-root-12 {
  padding: 13px !important;
}
.blog_title_main {
  font-size: 2rem;
}
.data_blog {
  align-items: center;
}
.blog_detail_img {
  width: 100% !important;
  /* height: 539px !important; */
  height: auto !important;
  border-radius: 14px;
  margin-top: 1rem;
}
.share-icons-react {
  display: flex;
  gap: 0.6rem;
}
@media only screen and (min-width: 701px) and (max-width: 960px) {
  .data_blog {
    align-items: center;
  }
  .container_Pages_sugg {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto auto !important;
  }
}
@media only screen and (min-width: 301px) and (max-width: 760px) {
  .data_blog {
    align-items: center;
  }
  .latest_featurs {
    grid-template-columns: repeat(2, auto);
    display: grid;
  }
  .related_blog {
    display: grid;
    grid-template-columns: 1fr;
  }
  .container_Pages_sugg {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: auto;
  }
  .blog_title_main {
    color: #000;
    font-size: 18px;
  }
  .blog_sec {
    font-size: 21px;
  }
  .search_box_astromall_new {
    display: none;
  }
  .blog_detail_img {
    width: 100% !important;
    /* height: 334px !important; */
    height: auto !important;
  }
  .MuiBox-root-51 {
    padding: 1rem 0 !important;
  }
  .blog-section-related {
    font-size: 15px;
    margin-top: 1rem;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
  }
  .image-img-blog {
    width: 100%;
    border-radius: 0% !important;
    height: auto !important;
  }
}
