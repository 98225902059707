.chat-astro-container-section {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
}

.online-status {
  color: var(--clr-primary) !important;
  background-color: #fff;
  border: 1px solid var(--clr-primary) !important;
}
.online-status_offline {
  color: #a21a47 !important;
  background-color: #cfcfcf;
  border: 2px solid #a21a3a !important;
}

.btn {
  padding: 0;
}

.btn .offline-status {
  color: #959595;
  border: 1px solid #959595;
  background-color: #fff;
}
.btn .busy-status {
  color: red;
  border: 1px solid red;
  background-color: #fff;
}

.btn_chat span svg {
  margin-top: -0.2rem;
}

.btn .btn_chat {
  font-size: 16px;
  padding: 0.1rem 1.8rem;
  display: block;
  /* border-radius: 10px; */
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  outline: none;
  float: right;
  margin-bottom: 0;
  /* margin-top: 86px; */
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_chat span {
  font-size: 1.2rem;
}
.total_user {
  font-size: 10px;
}
.astro_data_data img {
  height: 121px;
  border-radius: 50%;
  width: 127px;
  padding: 3px;
}
.user_astro_name {
  padding-left: 19px;
  padding-top: 11px;
}
.per_mit {
  font-size: 13px;
}
.astro_name {
  font-size: 18px;
  font-weight: 600;
}
.picture_profile {
  padding: 10px;
  background-color: orange;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.astro_comment_section {
  overflow-y: scroll !important;
  height: 350px !important;
}
.signupbox {
  background-color: var(--clr-primary);
  padding: 10px 3px;
  color: #ffff;
}
.sign_up_free {
  text-align: center;
  padding-top: 8px;
  font-size: 17px;
  color: #fff;
  float: left;
}
.sign_up_freee {
  padding-top: 8px;
  font-size: 17px;
  color: #fff;
  float: left;
}
.signup_form {
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
  justify-content: space-around;
}
.get_otp_btn_chat {
  background-color: #1b305d70;
  border: none;
  width: 100%;
  margin-top: 5px;
  padding: 11px 67px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0 0 2px 2px #fff;
  color: #fff 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
}
.get_otp_btn_chat:hover {
  background-color: var(--clr-primary);
}
.productFilter_filterHeading {
  font-size: 1.2rem;
  color: rgb(var(--black));
  margin-bottom: 7px;
}

/* Manish's css */

.chat_container {
  display: flex;
  flex-direction: column;
}

#card {
  height: 150px;
  width: 100%;
  border-radius: 2rem;
}

.card_div {
  border-radius: 1rem !important;
}

#card .card_top {
  height: 80%;
  width: 100%;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
}

.card_top .top_left {
  height: 100%;
  width: 20%;
}

.card_top .top_right {
  height: 100%;
  width: 80%;
  padding: 0 0 0 0.8rem;
}

.top_right .astro_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  margin-top: -0.3rem;
}
.astro_detail .name {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0;
}
.astro_detail .exp {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.card_top .top_right .prof {
  display: flex;
  justify-content: start;
  height: 20%;
}
.card_top .top_right .lang {
  display: flex;
  justify-content: start;
  height: 20%;
}
.card_top .top_right .star {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
}

.star_container {
  margin-top: -0.3rem;
}

.left img {
  height: 4.5rem;
  width: 100%;
  border-radius: 0.5rem;
}

#card .card_bottom {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}

.btn button {
  font-size: 2rem;
}
.responsive-filter {
  display: none;
}

@media only screen and (min-width: 301px) and (max-width: 960px) {
  .reviews_data {
    display: none;
  }

  .btn .btn_chat {
    padding: 0 0.8rem;
  }
  .chat-astro-container-section {
    grid-template-columns: repeat(1, auto);
  }
}
@media only screen and (max-width: 401px) {
  .astro_detail .exp {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 301px) and (max-width: 800px) {
  .responsive-filter {
    display: block;
  }
  .responsive-filter {
    display: flex;
    justify-content: space-around;
    background: #f8f8f8;
    align-items: baseline;
    text-transform: uppercase;
    font-weight: 500;
    justify-content: space-around;
    /* border-bottom: 1px solid; */
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .filter-hidden {
    display: none;
  }
  .search-hidden {
    display: block !important;
  }
  .free_kundli_banner {
    display: none !important;
  }
  .left img {
    width: 100%;
    border-radius: 50% !important;
    height: 4rem !important;
    width: 4rem !important;
  }
}
