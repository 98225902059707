.navbar-top {
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgb(252 187 130) 0%, rgb(255 0 0 / 0%) 105%);
}
.main-menu ul {
  display: flex;
  justify-content: space-around;
  padding-block: 1rem;
  flex-wrap: wrap;
  border-bottom: 2px solid #fc7601;
}

.main-menu ul li {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.main-menu ul li .sub-menu {
  padding: 0;
  position: absolute;
  left: 0;
  top: 120% !important;
  width: fit-content;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 99;
  height: auto;
}
.main-menu ul li .sub-menu li {
  display: block;
  margin: 0;
  display: block;
  padding: 7px 15px;
  width: 200px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0;
  line-height: 2;
  margin: 0;
  border-bottom: 2px solid #fc7601;
  color: #1b3255;
}
.main-menu ul li .sub-menu li :hover {
  color: #fff;
  border-color: transparent;
}
.main-menu ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
@media only screen and (min-width: 300px) and (max-width: 1000px) {
  .main-menu {
    display: none;
  }
}
