@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: var(--Roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --Roboto: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --clr-primary: #fc7601;
  --padding-inline: 2rem;
  --padding-block: 0.3rem;
  --margin-inline: 8.375rem;
}
.page-layout {
  padding-inline: var(--padding-inline);
}
h1 {
  text-transform: uppercase;
  color: black !important;
}

span {
  font-family: var(--Roboto);
  font-weight: 400;
}
p {
  font-family: var(--Roboto);
  font-weight: 400;
}

/* //Tab */
@media (max-width: 1024px) {
  .page-layout {
    padding-inline: 2.5rem;
  }
}

@media (max-width: 767px) {
  .page-layout {
    padding-inline: 0.5rem;
  }

  p {
    font-size: 14px;
  }
  li {
    font-size: 14px;
  }
}
