.discussed_btn {
  background: var(--clr-primary);
  padding: 6px;
  color: #fff;
  font-size: 20px;
  position: relative;
  top: 37px;
  width: 88%;
  text-align: center;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin: auto;
  box-shadow: 0 0 0 1px #ffffff;
}
.numerology_section {
  background: linear-gradient(-45deg, #ee7752, #fc7601, #ffeb00);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  padding: 2%;
}
.numerology_form_item {
  display: grid;
  grid-template-columns: repeat(4, auto);
}

@media only screen and (min-width: 301px) and (max-width: 960px) {
  .num_reading_section {
    padding: 1rem 0 4rem 0;
  }
  .numerology_form_item {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
  }
}
