#cart_container {
  padding-bottom: 2rem;
  display: flex;
}

.header {
  width: 70%;
  border-right: 1px solid lightgrey;
  padding: 0 1rem;
}
.header_cart {
  border-right: 1px solid lightgrey;
  padding: 0 1rem;
  width: 100%;
}

.header .head {
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.item-details {
  height: auto;
  width: 30%;
  background: #fafafa;
  padding: 0 1rem;
}

.item-details .head {
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.order-summery {
  width: 100%;
}

.total {
  width: 100%;
}

.order-summery .total div {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: grey;
  margin-bottom: 0.2rem;
}

.order-btn {
  width: 100%;
  background: #000;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0.3rem 0;
  border: none;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cart-item {
  /* height: 10rem; */
  width: 100%;
  border: 1px solid lightgrey;
  margin-bottom: 1rem;
  display: flex;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

#cart-item .image {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cart-item .image img {
  height: 100%;
  width: 100%;
}

#cart-item .details {
  height: 100%;
  width: 35%;
  padding: 0 0.5rem;
}

.details .desc {
  color: grey;
  font-size: 0.8rem;
}

.details .price {
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0.2rem 0;
}

.details .detail-btn {
  width: 60%;
  background: #000;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
}

.detail-btn:hover {
  background: #080808b3;
}

#cart-item .quantity {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.quantity button {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
}

.quantity input {
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  text-align: center;
  outline: grey;
}

#cart-item .total {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: end;
  align-items: start;
}

.total .price {
  font-weight: bold;
  font-size: 1rem;
}

@media only screen and (min-width: 301px) and (max-width: 760px) {
  .details .detail-btn {
    width: 144%;
    margin-top: 12px;
  }
  #cart-item .image {
    width: 100%;
  }
  #cart-item .details {
    width: 100%;
  }
  #cart-item .quantity {
    width: 100%;
  }
  #cart-item {
    display: block;
  }
  #cart-item .total {
    width: 100%;
  }
  #cart-item .quantity {
    display: block;
  }
}
