.get_otp_btn_userprofile_btn {
  width: 25%;
  margin: auto;
  background-color: var(--clr-primary);
  border: none;
  margin-top: 5px;
  padding: 11px 67px;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0 0 2px 2px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
}
.get_otp_btn_userprofile_btn:hover {
  color: #fff;
}
.Contact_Page_padding {
  padding-top: 12px;
  background-color: #f2f2f2cf;
}
.note-text {
  font-size: 16px;
  color: #777;
}
@media only screen and (min-width: 301px) and (max-width: 960px) {
  .get_otp_btn_userprofile_btn {
    width: 45%;
    margin: auto;
    background-color: var(--clr-primary);
    border: none;
    margin-top: 5px;
    padding: 11px 9px;
    border-radius: 10px;
    font-size: 12px;
    box-shadow: -18 0 2px 2px #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 10px;
  }
}
