.chat_box {
  margin: 0 auto;
  max-width: 800px;
  /* padding: 0 20px; */
  margin-top: 15px;
  margin-bottom: 15px;
}

.containerr {
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.containerr::after {
  content: "";
  clear: both;
  display: table;
}

.containerr img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.containerr img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.chat_scrool {
  overflow-y: auto;
}

/* Manish's css */

#chat_container {
  background-color: #e6e6e67a;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat_box {
  height: 85%;
  width: 70%;
  background-color: #fdfdfd;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
}

.chat_header {
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 8px 10px;
}

.chat_header .header_icon {
  padding: 0.5rem;
  cursor: pointer;
}

.chat_header .header_icon svg {
  height: 100%;
  width: 100%;
}

.chat_header .astro_name {
  padding: 0 0.5rem 0 0;
}
.end_chat_btn {
  background-color: var(--clr-primary);
  padding: 3px 22px;
  border-radius: 10%;
  color: #fff;
}

.chat_header .astro_name .astro_profile_pic img {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
}

.chat_messages {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.msg_container {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-bottom-input-box {
  display: flex;
  margin: 0 10px 10px;
  padding: 0 0 0 10px;
  gap: 0.5rem;
  background-color: #00000011;
  border-radius: 100px;
}
.btm_input {
  flex-grow: 1;
  outline: none;
  border: none;
  border-radius: 100px;
  font-size: 18px;
  padding-inline: 10px;
  font-weight: normal !important;
  background-color: transparent;
}
.send-chat-btn {
  display: grid;
  place-items: center;
  font-size: 26px;
  padding: 12px;
  border-radius: 50%;
  background-color: var(--clr-primary);
  color: #ffffff;
}

.single_msg {
  min-width: 70%;
  /* max-width: 70%; */
  display: flex;
  justify-content: flex-end;
}

.me {
  float: right;
}

.other {
  float: left;
  flex-direction: row-reverse;
}

.msg_container .msg_text {
  max-width: 70%;
}

.msg_container .msg_text p {
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0;
  margin-right: 0.7rem;
  overflow-wrap: break-word;
}

.msg_text::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.msg_container .msg_text .msg_time {
  text-align: right;
  color: #858584;
  font-size: 1rem;
}

.msg_container .msg_img {
  width: 18%;
  padding-left: 0.5rem;
}

.msg_container .msg_img img {
  border-radius: 50%;
}

@media only screen and (max-width: 960px) {
  .chat_box {
    height: 100%;
    width: 100%;
    margin: 0;
  }
}

/* scrollbar */
.msg_container::-webkit-scrollbar {
  width: 0.5rem;
}

.msg_container::-webkit-scrollbar {
  width: 0.2rem;
}

.msg_container::-webkit-scrollbar-track-piece {
  background: #fff;
}
.msg_container::-webkit-scrollbar-thumb {
  background: #ccc;
}
