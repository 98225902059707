.terms-content {
  padding: 16px;
  gap: 12px;
  background: #fdfdfd;
  border: 1px solid #eef0f1;
  box-shadow: 0px 4px 10px rgba(43, 54, 67, 0.04);
  border-radius: 12px;
  margin-block: 1rem;
}
.terms-data {
  border-bottom: 1px solid #eef0f1;
  padding-bottom: 1rem;
}
.terms-data h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #1a1a1a;
  margin-block: 1rem 0.5rem;
}
.terms-data p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #394750;
}
.termsof-heading h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}
.termsof-heading p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #394750;
}
.faq-title {
  justify-content: space-between;
  cursor: pointer;
  gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
}
.termsof-service {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  background: #fff9ed;
  border-radius: 24px;
  margin-bottom: 1rem;
}
.faq-heading h3 {
  font-size: 18px;
}

/* //Tab */
@media (max-width: 1024px) {
}
/* //mobile */

@media (max-width: 767px) {
  .termsof-service {
    padding: 9px;
  }
}
