#section {
  padding-bottom: 3rem;
}

.tab_items div {
  font-weight: bold;
  color: gray;
  padding: 0.3rem 0.5rem;
}

.tab_items div:hover {
  font-weight: bold;
  color: #000;
  cursor: pointer;
}
.report_detail_header {
  display: inline;
  justify-content: space-between;
  border-bottom: 1px solid;
  display: flex;
}
.report_text {
  display: inline;
  justify-content: space-between;
  display: flex;
}
.transation_data {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tab_items .active {
  font-weight: bold;
  color: #000;
  background-color: #d3d3d37a;
}

.chat_container {
  display: flex;
  flex-direction: column;
}

#card {
  height: 150px;
  width: 100%;
  border-radius: 2rem;
  font-family: var(--Roboto);
}
.chat_history {
  background-color: var(--clr-primary);
  padding: 3px 6px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;
}

.card_div {
  border-radius: 1rem !important;
}

#card .card_top {
  height: auto;
  width: 100%;
  display: flex;
  padding: 0.5rem;
}

.card_top .transactions_top_left {
  height: 100%;
  width: 10%;
}

.transactions_top_right {
  height: 100%;
  width: 90%;
  padding: 0 0 0 0.8rem;
}

.transactions_top_right .astro_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  margin-top: -0.3rem;
}

.astro_detail .name {
  font-family: var(--Roboto);
  font-weight: 600;
  color: var(--clr-primary);
}

.astro_detail .transactions_user_id {
  font-size: 0.8rem;
  font-weight: 400;
  color: gray;
  margin: 0;
  padding: 0;
}

.transactions_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  padding: 0.5rem 0;
}

.call_history {
  font-size: 0.9rem;
}

.call_price {
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}

.card_top .transactions_top_right .prof {
  display: flex;
  justify-content: start;
  height: 20%;
}

.card_top .transactions_top_right .lang {
  display: flex;
  justify-content: start;
  height: 20%;
}

.card_top .transactions_top_right .star {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
}

.star_container {
  margin-top: -0.3rem;
}

.left img {
  /* height: 4.5rem;
  width: 100%;
  border-radius: 0.5rem; */
  border-radius: 50% !important;
  height: 4rem !important;
  width: 4rem !important;
}

#card .transactions_top_bottom {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.btn button {
  font-size: 2rem;
}

.transactions_btn button {
  padding: 0 1rem;
  background-color: #e7554d;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;
}

.status {
  padding: 0.5rem 0 0.5rem 0.5rem;
}

/* /////user walete */

.wallete_card_detail {
  border: none;

  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.fonts {
  font-size: 11px;
}

.social-list {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
}

.social-list li {
  padding: 10px;
  color: #0c217d;
  font-size: 19px;
}

.buttons button:nth-child(1) {
  border: 1px solid #0c217d !important;
  color: #0c217d;
  height: 40px;
}

.buttons button:nth-child(1):hover {
  border: 1px solid #0c217d !important;
  color: #fff;
  height: 40px;
  background-color: #0c217d;
}

.buttons button:nth-child(2) {
  border: 1px solid #0c217d !important;
  background-color: #0c217d;
  color: #fff;
  height: 40px;
}
